/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

import Layout from '../../components/layout/Layout';
import SEO from '../../components/layout/SEO';
import Spinner from '../../components/spinner/Spinner';
import Tick from '../../components/tick/Tick';

import * as styles from '../../css/pages/Auth.module.scss';

const JustGiving = ({ location }: { location: any }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const [auth, setAuth] = useState('');
    const [refresh, setRefresh] = useState('');

    const params = new URLSearchParams(location.search);
    const code = params.get('code');

    useEffect(() => {
        // (async () => {
        //     const res = await fetch(
        //         `https://app.roundups.org/api/roundupusers/v1/just-giving-auth/?code=${encodeURIComponent(
        //             code,
        //         )}`,
        //     );
        //     const resJson = await res.json();
        //     if (resJson.status === 'SUCCESS') {
        //         setLoading(false);
        //     } else {
        //         setError('Something went wrong');
        //     }
        // })();
    }, []);

    return (
        <>
            <SEO
                title="Just Giving Authorisation"
                path="/auth/just-giving/"
                allowIndexing={false}
            />
            <Layout>
                <section className={styles.section}>
                    {loading ? (
                        <>
                            <h1>Verifying...</h1>
                            <div className={styles.spinnerContainer}>
                                <Spinner />
                            </div>
                        </>
                    ) : (
                        <>
                            <h1>Successful authorisation!</h1>
                            <div className={styles.tickContainer}>
                                <Tick />
                                <p>Code: {code}</p>
                                <p>Auth: {auth}</p>
                                <p>Refresh: {refresh}</p>
                            </div>
                        </>
                    )}
                </section>
            </Layout>
        </>
    );
};

export default JustGiving;
